<template>
  <div class="entity-detail-table">
    <b-table
      :busy="is.loading"
      :fields="propertiesTable.fields"
      :items="propertiesTable.items"
      borderless
      striped
      hover
      show-empty
      sort-icon-left
      :tbody-tr-class="table.helper.rowClass"
    >
      <template #table-busy>
        <div class="text-center">
          <loader inline />
          <div>{{ $t("components.table.busy") }}</div>
        </div>
      </template>

      <template #empty>
        <p class="my-2 text-center">{{ $t("components.table.empty") }}</p>
      </template>

      <template #emptyfiltered>
        <p class="my-2 text-center">{{ $t("components.table.emptyfiltered") }}</p>
      </template>

      <template #cell($label)="data">
        {{ data.item.control.label }}
      </template>

      <template #cell(control)="data">
        <div
          :class="['control-edit-group', { 'is-readonly': readOnly || data.value.readonly, 'is-edit-mode': data.value.editMode }]"
          @click="propertyToggleEditMode(data.item, true, data.index)"
        >
          <loader v-if="data.value.editMode && is.sending" />

          <b-form-row class="align-items-center">
            <b-col>
              <div v-if="readOnly || data.value.readonly || !data.value.editMode" class="form-control-plaintext">
                <component
                  v-if="data.value.requiredValueFormatterComponent"
                  :is="valueFormatter(definition, data.item.name, data.item.control.value).component.is"
                  v-bind="
                    Object.assign({ labelHide: true }, data.value, valueFormatter(definition, data.item.name, data.item.control.value).component.properties)
                  "
                />

                <div v-else v-html="valueFormatter(definition, data.item.name, data.item.control.value).html" />
              </div>

              <component
                v-else-if="data.item.visible"
                :ref="`control.${data.index}`"
                :is="data.value.is"
                v-model="data.value.value"
                v-bind="data.value"
                labelHide
                @input="($value) => onInput($value, data.item)"
                @change="($value) => onChange($value, data.item)"
                @keydown.native.enter="($value) => propertySave(data.item)"
                @keydown.native.esc="($value) => propertyReset(data.item)"
              />
            </b-col>

            <b-col v-if="!readOnly && !data.value.readonly" cols="auto">
              <b-button-group v-if="data.value.editMode">
                <b-button
                  class="btn-reset"
                  variant="dark"
                  size="sm"
                  :title="$t('components.entity.detailtable.control.action.reset')"
                  @click.stop="propertyReset(data.item)"
                >
                  <b-icon icon="reset" />
                </b-button>

                <b-button
                  class="btn-save"
                  variant="success"
                  size="sm"
                  :title="$t('components.entity.detailtable.control.action.save')"
                  @click.stop="propertySave(data.item)"
                >
                  <b-icon icon="save" />
                </b-button>
              </b-button-group>

              <b-button v-else class="btn-edit" variant="link" size="sm" :title="$t('components.entity.detailtable.control.action.toggle')">
                <b-icon icon="edit" />
              </b-button>
            </b-col>
          </b-form-row>
        </div>
      </template>

      <template #cell(changeRequests)="data">
        <div class="entity-changerequest" v-for="changeRequest in data.value" :key="changeRequest.id">
          <b-row align-v="center">
            <b-col cols="lg">
              <div class="changerequest-value" v-html="valueFormatter(definition, changeRequest.propertyName, changeRequest.value).html" />
              <div class="changerequest-user">{{ changeRequest.userName }}</div>
            </b-col>

            <b-col cols="lg">
              <div v-if="changeRequest.status === CHANGEREQUEST_STATES.open" class="changerequest-action">
                <div class="btn-list">
                  <b-button variant="outline-success" size="sm" @click="onAccept(changeRequest)">{{
                    $t("components.entity.detailtable.changerequest.action.accept")
                  }}</b-button>
                  <b-button variant="outline-danger" size="sm" @click="onDecline(changeRequest)">{{
                    $t("components.entity.detailtable.changerequest.action.decline")
                  }}</b-button>
                </div>
              </div>

              <b-alert v-else-if="changeRequest.status === CHANGEREQUEST_STATES.approved" class="changerequest-info" show variant="info">
                <b-icon icon="circle-info" /> {{ $t("components.entity.detailtable.changerequest.info.approved") }}
              </b-alert>
            </b-col>
          </b-row>
        </div>
      </template>

      <template #row-details="data">
        <template v-if="data.item.isTitle">
          <h3 class="h5">{{ data.item.title }}</h3>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
import { CHANGEREQUEST_STATES } from "@/constants";

import { valueFormatter, propertyMapper } from "@/assets/js/helper/entity";
import Table, { TableHelper } from "@/assets/js/helper/table";

import Loader from "@/components/Loader";
import ControlInput from "@/components/form/ControlInput";
import ControlSelect from "@/components/form/ControlSelect";
import ControlMultiselect from "@/components/form/ControlMultiselect";
import ControlTextarea from "@/components/form/ControlTextarea";
import ControlCheckbox from "@/components/form/ControlCheckbox";
import ControlRadio from "@/components/form/ControlRadio";
import ControlFiles from "@/components/form/ControlFiles";
import ControlDatepicker from "@/components/form/ControlDatepicker";
import ControlLocalizedInput from "@/components/form/ControlLocalizedInput";
import ControlUnknown from "@/components/form/ControlUnknown";

const ALLOWED_CHANGEREQUEST_STATES = [CHANGEREQUEST_STATES.open, CHANGEREQUEST_STATES.approved];

export default {
  name: "EntityDetailTable",
  components: {
    Loader,
    ControlInput,
    ControlSelect,
    ControlMultiselect,
    ControlTextarea,
    ControlCheckbox,
    ControlRadio,
    ControlFiles,
    ControlDatepicker,
    ControlLocalizedInput,
    ControlUnknown,
  },
  props: {
    entityKey: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CHANGEREQUEST_STATES,
      table: {
        helper: TableHelper,
        options: {
          fields: {
            includes: ["$label"],
            excludes: [],
            sorting: ["$label", "control", "changeRequests"],
            sortable: [],
            labelKey: "components.entity.detailtable.label.{field}",
            label: {},
            variant: {},
            class: {
              property: "cell-min",
              control: "cell-min",
            },
            thClass: {},
            tdClass: {},
          },
        },
      },
      validationValues: {},
    };
  },
  validations() {
    return {
      validationValues: this.properties.reduce((properties, p) => {
        this.$set(this.validationValues, p.name, p.control.value);
        return Object.assign(properties, { [p.name]: p.control.validations });
      }, {}),
    };
  },
  computed: {
    is() {
      return this.$store.getters[`${this.entityKey}/is`];
    },
    validators() {
      return this.$store.getters["validators/get"];
    },
    definition() {
      return this.$store.getters[`${this.entityKey}/definition`];
    },
    entity() {
      return this.$store.getters[`${this.entityKey}/getEntity`](this.entityId) || {};
    },
    properties() {
      return propertyMapper(this.definition, this.$i18n.locale, this.validators, this.entity, ALLOWED_CHANGEREQUEST_STATES);
    },
    propertiesTable() {
      return new Table(this.properties, this.table.options.fields);
    },
  },
  methods: {
    valueFormatter,
    propertyToggleEditMode(property, state = null, rowIndex = null) {
      property.control.editMode = state !== null ? state : !property.control.editMode;

      if (rowIndex !== null) {
        this.propertyResetOthers(property);
        if (property.control.is !== "control-localized-input") {
          this.propertyFocus(rowIndex);
        }
      }
    },
    propertyFocus(rowIndex) {
      this.$nextTick(() => {
        const refElement = (this.$refs[`control.${rowIndex}`] || {}).$el || { querySelector: function () {} };
        const controlElement = refElement.querySelector(".form-control, .custom-select");

        if (controlElement) controlElement.focus();
      });
    },
    propertyResetOthers(property) {
      this.properties
        .filter((p) => p !== property)
        .forEach((p) => {
          this.propertyReset(p);
        });
    },
    propertyReset(property) {
      property.reset();
      this.$v.validationValues[property.name].$reset();
      this.propertyToggleEditMode(property, false);
    },
    propertySave(property) {
      if (!this.$v.validationValues[property.name].$invalid) {
        if (property.control.value === property.control.resetValue) {
          this.propertyReset(property);
        } else {
          this.$emit("entity:save", Object.assign({}, this.entity.value, { [property.name]: property.control.value }));
          property.update();

          setTimeout(() => {
            this.propertyReset(property);
          }, 2000);
        }
      }
    },
    onInput(controlValue, property) {
      property.change();
      this.$emit("control:input", { [property.name]: controlValue });
    },
    onChange(controlValue, property) {
      property.change();
      this.$emit("control:change", { [property.name]: controlValue });
    },
    onAccept(changeRequest) {
      this.$store.dispatch(`${this.entityKey}/acceptChangeRequest`, changeRequest.id);
    },
    onDecline(changeRequest) {
      this.$store.dispatch(`${this.entityKey}/declineChangeRequest`, changeRequest.id);
    },
  },
  created() {
    TableHelper.rowClassHook((property) => (property.visible ? null : "is-invisible"));
  },
};
</script>

<style lang="scss">
$entitydetailtable-control-bg: rgba($black, 0.1) !default;

$entitydetailtable-controleditgroup-min-width: 320px !default;
$entitydetailtable-controleditgroup-edit-color: inherit !default;
$entitydetailtable-controleditgroup-hover-edit-color: $change !default;

$entitydetailtable-changerequest-gap: $spacer * 0.5 !default;

$entitydetailtable-changerequest-value-font-size: inherit !default;
$entitydetailtable-changerequest-value-font-weight: $font-weight-bold !default;
$entitydetailtable-changerequest-value-color: inherit !default;

$entitydetailtable-changerequest-user-font-size: $font-size-xs !default;
$entitydetailtable-changerequest-user-font-weight: inherit !default;
$entitydetailtable-changerequest-user-color: $change !default;

$entitydetailtable-changerequest-info-font-size: $font-size-sm !default;
$entitydetailtable-changerequest-info-font-weight: inherit !default;

.entity-detail-table {
  tr {
    th,
    td {
      &.col-id-control {
        background-color: $entitydetailtable-control-bg;
      }
    }

    td {
    }

    &.is-invisible {
      display: none;
    }
  }

  .control-edit-group {
    position: relative;
    min-width: $entitydetailtable-controleditgroup-min-width;

    .form-group {
      margin: 0;
    }

    .btn-reset {
    }

    .btn-save {
    }

    .btn-edit {
      color: $entitydetailtable-controleditgroup-edit-color;
    }

    &:hover {
      cursor: pointer;

      .form-control-plaintext {
        cursor: pointer;
      }

      .btn-edit {
        color: $entitydetailtable-controleditgroup-hover-edit-color;
      }
    }

    &.is-readonly {
      cursor: default;

      .form-control-plaintext {
        cursor: default;
      }
    }

    &.is-edit-mode {
      &:hover {
        cursor: default;
      }
    }
  }

  .entity-changerequest {
    .changerequest-value {
      font-size: $entitydetailtable-changerequest-value-font-size;
      font-weight: $entitydetailtable-changerequest-value-font-weight;
      color: $entitydetailtable-changerequest-value-color;
    }

    .changerequest-user {
      font-size: $entitydetailtable-changerequest-user-font-size;
      font-weight: $entitydetailtable-changerequest-user-font-weight;
      color: $entitydetailtable-changerequest-user-color;
    }

    .changerequest-info {
      margin: 0;
      font-size: $entitydetailtable-changerequest-info-font-size;
      font-weight: $entitydetailtable-changerequest-info-font-weight;
    }

    .changerequest-action {
      text-align: right;
    }

    + .entity-changerequest {
      margin-top: $entitydetailtable-changerequest-gap;
    }
  }
}
</style>
